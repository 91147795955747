var jq = jQuery.noConflict();

jq(document).ready(function(){

    setGlobalVars();

    identifyDevice();

    treatNavbar();

    handleErrorMsgs();

    slider();

    homepage();

    scrollToElement();

    setModals();

    jq(function() {
        FastClick.attach(document.body);
    });

});

jq(window).resize(function(){
	homepage();
});


function handleErrorMsgs(){

}

function setGlobalVars(){
    var getUrl = window.location;
    var baseUrl = getUrl .protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];
    baseDir = getUrl .protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];

    viewportWidth = jq(window).width()+16;
    viewportHeight = jq(window).height();

    documentHeight = jq(document).height();

    isMobileCheck = false;
    isMobileOrTablet = false;
};

function identifyDevice() {
    if(viewportWidth<768) {
        jq("body").addClass("mobile");
        isMobileCheck = true;
        isMobileOrTablet = true;
    }else if(767<viewportWidth && viewportWidth<992){
        jq("body").addClass("tablet");
        isMobileOrTablet = true;
        isMobileCheck = false;
    }else {
        jq("body").addClass("desktop");
    }
};

function treatNavbar() {
  var slideout = new Slideout({
    'panel': document.getElementById('panel'),
    'menu': document.getElementById('menu'),
    'padding': 256,
    'tolerance': 70,
    'side': 'right'
  });
  document.querySelector('.menu-link').addEventListener('click', function() {
        slideout.toggle();
        jq(".navbar-toggle.menu-link").toggleClass("active");
    });
};


function slider(){
    if(jq(".reviewsCarousel").length){
	    jq(".reviewsCarousel").slick({
	        dots: true,
	        arrows: true,
	        speed: 1000,
	        autoplay: true,
            autoplaySpeed: 10000,
	        slidesToShow: 1,
	        slidesToScroll: 1
	    });
    }
    if(jq(".servicesCarousel").length){
    	if(isMobileCheck){
		    jq(".servicesCarousel .carousel").slick({
		        dots: false,
		        arrows: true,
		        speed: 1000,
		        //autoplay: false,
		        slidesToShow: 1,
		        slidesToScroll: 1
		    });
		}else{
		    jq(".servicesCarousel .carousel").slick({
		        dots: false,
		        arrows: true,
		        speed: 1000,
		        //autoplay: false,
		        slidesToShow: 3,
		        slidesToScroll: 3
		    });
		}
    }
}

function homepage(){

	if(jq("#home").length){
		//var cw = jq('#homepage-gallery .grid-col.col-2 .item:nth-child(0n+2) .image').width();
		//jq('#homepage-gallery .grid-col.col-2 .item:nth-child(0n+2) .image').css({'height':(cw)/1.7281105990783410138248847926267*2+8+'px'});
		//var cx = jq('#homepage-gallery .grid-col.col-2 .item:nth-child(0n+3) .image').width();
		//jq('#homepage-gallery .grid-col.col-2 .item:nth-child(0n+3) .image').css({'height':(cw)/1.7281105990783410138248847926267*2+8+'px'});
	}

}

function scrollToElement() {
    var hash = window.location.hash;
    if(hash.length != 0) {
        if(jq(hash).length){
            jq('html, body').animate({ scrollTop: (jq(hash).offset().top-20) });
        }
    }
    jq(".scrollTo").click(function(){
        hash = jq(this).attr("href");
        jq('html, body').animate({ scrollTop: (jq(hash).offset().top-20) });
    });
}

function setModals(){
    
    jq('#homepage-gallery').magnificPopup({
      delegate: '.image a', // child items selector, by clicking on it popup will open
      type: 'image',
      fixedContentPos: true,
      gallery:{enabled:true},
      image:{
        markup: '<div class="mfp-figure">' +
             '<div class="mfp-close"></div>' +
             '<figure>'+
             '<div class="mfp-img"></div>' +
             '</figure>' +
           '</div>',
           cursor: 'mfp-zoom-out-cur'
      },
      callbacks: {
        elementParse: function(item) {
        // the class name
            if(item.el.attr('class') == 'video-link') {
                item.type = 'iframe';
            } else {
                item.type = 'image';
            }
        }
      },
    });

    jq('.contactLink').magnificPopup({
        type:'inline',
        midClick: true,
        fixedContentPos: true,
    });
    jq('.mapLink').magnificPopup({
        type:'inline',
        midClick: true,
        fixedContentPos: true
    });
    jq('.titleVidLink').magnificPopup({
        type:'inline',
        midClick: true,
        fixedContentPos: true
    });
    jq("body").swipe({
        swipeLeft: function(event, direction, distance, duration, fingerCount) {
            jq(".mfp-arrow-left").magnificPopup("prev");
        },
        swipeRight: function(event, direction, distance, duration, fingerCount) {
            jq(".mfp-arrow-right").magnificPopup("next");
        },
        threshold: 50
    });
}